var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        permanent: "",
        "mini-variant": "",
        app: "",
        "mobile-breakpoint": "600",
        "mini-variant-width": "65",
        color: "sidebarLight"
      },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function() {
            return undefined
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "d-flex flex-column pa-0" }, [
        _c(
          "div",
          { staticClass: "order-1 pa-0" },
          [
            _c(
              "v-menu",
              {
                attrs: {
                  rounded: "0",
                  "close-on-content-click": true,
                  "offset-y": "",
                  "open-on-click": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            {
                              staticClass: "ma-0 pa-0",
                              staticStyle: {
                                height: "48px",
                                "background-color": "#6D6E70"
                              }
                            },
                            on
                          ),
                          [
                            _c("v-list-item-content", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center justify-center",
                                  staticStyle: { height: "100%" }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      "max-width": "50",
                                      "max-height": "50",
                                      src: require("@/assets/N-Nuvolos-dark-gray.svg")
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("v-list-item-title", [_vm._v("Dashboard")])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _c(
                  "v-list",
                  { staticClass: "py-0", attrs: { dense: "" } },
                  [
                    _c(
                      "v-list-item",
                      {
                        attrs: {
                          to: {
                            name: "home-dashboard",
                            params: { oid: _vm.$route.params.oid }
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("home")
                            ])
                          ],
                          1
                        ),
                        _c("v-list-item-title", [_vm._v("Go to dashboard")])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "order-2" },
          [
            _c(
              "v-tooltip",
              {
                attrs: { right: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            {
                              attrs: {
                                to: {
                                  name: "snapshot-overview",
                                  params: {
                                    oid: _vm.$route.params.oid,
                                    sid: _vm.$route.params.sid,
                                    iid: _vm.$route.params.iid,
                                    snid: _vm.$route.params.snid
                                  }
                                }
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v("dashboard")])],
                              1
                            ),
                            _c("v-list-item-title", [_vm._v("Overview")])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v("Overview")])]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "order-3" },
          [
            _vm.isSpaceAdmin === true
              ? _c(
                  "v-menu",
                  {
                    attrs: {
                      "close-on-content-click": true,
                      "offset-x": "",
                      "max-width": "340",
                      "open-on-hover": "",
                      "close-delay": _vm.closingDelay,
                      rounded: "0"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-list-item",
                                _vm._g({}, on),
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("settings")])],
                                    1
                                  ),
                                  _c("v-list-item-title", [_vm._v("settings")])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3237631703
                    ),
                    model: {
                      value: _vm.manageMenu,
                      callback: function($$v) {
                        _vm.manageMenu = $$v
                      },
                      expression: "manageMenu"
                    }
                  },
                  [_c("SettingsMenu")],
                  1
                )
              : _vm._e(),
            _c("v-divider")
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "order-4" },
          [
            _c(
              "v-tooltip",
              {
                attrs: { right: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            {
                              attrs: {
                                to: {
                                  name: "snapshot-files",
                                  params: {
                                    oid: _vm.$route.params.oid,
                                    sid: _vm.$route.params.sid,
                                    iid: _vm.$route.params.iid,
                                    snid: _vm.$route.params.snid,
                                    localPath: _vm.currentFileLocalPath,
                                    fileArea: _vm.fileAreaType
                                  }
                                }
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v("folder")])],
                              1
                            ),
                            _c("v-list-item-title", [_vm._v("Files")])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v("Files")])]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "order-5" },
          [
            _c(
              "v-tooltip",
              {
                attrs: { right: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            {
                              attrs: {
                                to: {
                                  name: "snapshot-tables",
                                  params: {
                                    oid: _vm.$route.params.oid,
                                    sid: _vm.$route.params.sid,
                                    iid: _vm.$route.params.iid,
                                    snid: _vm.$route.params.snid
                                  }
                                }
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v("mdi-table-large")])],
                              1
                            ),
                            _c("v-list-item-title", [_vm._v("Tables")])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v("Tables")])]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "order-6" },
          [
            _c(
              "v-tooltip",
              {
                attrs: { right: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            {
                              attrs: {
                                to: {
                                  name: "snapshot-applications",
                                  params: {
                                    oid: _vm.$route.params.oid,
                                    sid: _vm.$route.params.sid,
                                    iid: _vm.$route.params.iid,
                                    snid: _vm.$route.params.snid
                                  }
                                }
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v("desktop_windows")])],
                              1
                            ),
                            _c("v-list-item-title", [_vm._v("Applications")])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v("Applications")])]
            ),
            _c("v-divider")
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "order-7" },
          [
            _c(
              "v-menu",
              {
                attrs: {
                  rounded: "0",
                  "close-on-content-click": true,
                  "offset-x": "",
                  "max-width": "300",
                  "open-on-hover": "",
                  "close-delay": _vm.closingDelay
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            {
                              attrs: {
                                "input-value": _vm.$route.path.includes(
                                  "manage-snapshots"
                                )
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v("camera_alt")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v("snapshot actions")
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.snapshotMenu,
                  callback: function($$v) {
                    _vm.snapshotMenu = $$v
                  },
                  expression: "snapshotMenu"
                }
              },
              [
                _c(
                  "v-list",
                  { attrs: { nav: "", dense: "" } },
                  [
                    _vm.currentSpaceType !== _vm.spaceTypes.VENDOR_SPACE &&
                    _vm.isDevelopment === true &&
                    _vm.isInstanceEditor === true
                      ? _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addSnapshot(
                                  _vm.snapshotTypes.QUICK_SNAPSHOT
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2 d-flex align-center" },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-1", attrs: { small: "" } },
                                  [_vm._v("mdi-camera-plus-outline")]
                                ),
                                _vm._v("Quick snapshot")
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-action",
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { right: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "grey lighten-1"
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v("mdi-information")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2792354461
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "This will create and save a snapshot of your current state, including all your files, tables, and applications."
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isDevelopment === true && _vm.isInstanceEditor === true
                      ? _c(
                          "v-list-item",
                          {
                            attrs: {
                              to: {
                                name: "snapshot-add-snapshot",
                                params: {
                                  oid: _vm.$route.params.oid,
                                  sid: _vm.$route.params.sid,
                                  iid: _vm.$route.params.iid,
                                  snid: _vm.$route.params.snid
                                }
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2 d-flex align-center" },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-1", attrs: { small: "" } },
                                  [_vm._v("mdi-camera-plus")]
                                ),
                                _vm._v("Take snapshot and describe")
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-action",
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { right: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "grey lighten-1"
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v("mdi-information")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2792354461
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Create a snapshot of your current state and provide detailed description."
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-list-item",
                      {
                        attrs: {
                          to: {
                            name: "instance-snapshots",
                            params: {
                              oid: _vm.$route.params.oid,
                              sid: _vm.$route.params.sid,
                              iid: _vm.$route.params.iid,
                              snid: _vm.$route.params.snid
                            }
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-item-title",
                          { staticClass: "subtitle-2 d-flex align-center" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-1",
                                attrs: { color: "info", small: "" }
                              },
                              [_vm._v("timeline")]
                            ),
                            _vm._v("View snapshot timeline")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "order-8" },
          [
            _c(
              "v-tooltip",
              {
                attrs: { right: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            {
                              attrs: {
                                to: {
                                  name: "snapshot-distribute",
                                  params: {
                                    oid: _vm.$route.params.oid,
                                    sid: _vm.$route.params.sid,
                                    iid: _vm.$route.params.iid,
                                    snid: _vm.$route.params.snid
                                  }
                                }
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c(
                                  "v-badge",
                                  {
                                    staticClass: "caption",
                                    attrs: {
                                      color:
                                        _vm.numberOfStagedObjects > 0
                                          ? "secondary"
                                          : "primary",
                                      overlap: "",
                                      bottom: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "badge",
                                          fn: function() {
                                            return [
                                              _c("v-fab-transition", [
                                                _c("span", {
                                                  attrs: { fab: "" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.numberOfStagedObjects
                                                    )
                                                  }
                                                })
                                              ])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.showBadge,
                                      callback: function($$v) {
                                        _vm.showBadge = $$v
                                      },
                                      expression: "showBadge"
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("share")])],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v("Distribute objects")
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v("Distribute objects")])]
            )
          ],
          1
        ),
        _c("div", { staticClass: "order-9" }, [_c("ApplicationMenu")], 1)
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }