<template>
    <v-navigation-drawer permanent mini-variant app mobile-breakpoint="600" mini-variant-width="65" color="sidebarLight">
        <div class="d-flex flex-column pa-0">
            <div class="order-1 pa-0">
                <v-menu rounded="0" :close-on-content-click="true" offset-y open-on-click>
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" class="ma-0 pa-0" style="height:48px;background-color:#6D6E70">
                            <v-list-item-content>
                                <div style="height:100%" class="d-flex align-center justify-center">
                                    <img max-width="50" max-height="50" src="@/assets/N-Nuvolos-dark-gray.svg" />
                                </div>
                            </v-list-item-content>
                            <v-list-item-title>Dashboard</v-list-item-title>
                        </v-list-item>
                    </template>
                    <v-list dense class="py-0">
                        <v-list-item :to="{ name: 'home-dashboard', params: { oid: $route.params.oid } }">
                            <v-list-item-icon>
                                <v-icon small>home</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Go to dashboard</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div class="order-2">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item
                            v-on="on"
                            :to="{
                                name: 'snapshot-overview',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                            }"
                        >
                            <v-list-item-icon>
                                <v-icon>dashboard</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Overview</v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>Overview</span>
                </v-tooltip>
            </div>
            <div class="order-3">
                <v-menu
                    v-if="isSpaceAdmin === true"
                    :close-on-content-click="true"
                    offset-x
                    v-model="manageMenu"
                    max-width="340"
                    open-on-hover
                    :close-delay="closingDelay"
                    rounded="0"
                >
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                            <v-list-item-icon>
                                <v-icon>settings</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>settings</v-list-item-title>
                        </v-list-item>
                    </template>
                    <SettingsMenu />
                </v-menu>
                <v-divider></v-divider>
            </div>
            <div class="order-4">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item
                            v-on="on"
                            :to="{
                                name: 'snapshot-files',
                                params: {
                                    oid: $route.params.oid,
                                    sid: $route.params.sid,
                                    iid: $route.params.iid,
                                    snid: $route.params.snid,
                                    localPath: currentFileLocalPath,
                                    fileArea: fileAreaType
                                }
                            }"
                        >
                            <v-list-item-icon>
                                <v-icon>folder</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Files</v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>Files</span>
                </v-tooltip>
            </div>
            <div class="order-5">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item
                            v-on="on"
                            :to="{
                                name: 'snapshot-tables',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                            }"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-table-large</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Tables</v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>Tables</span>
                </v-tooltip>
            </div>
            <div class="order-6">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item
                            v-on="on"
                            :to="{
                                name: 'snapshot-applications',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                            }"
                        >
                            <v-list-item-icon>
                                <v-icon>desktop_windows</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Applications</v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>Applications</span>
                </v-tooltip>
                <v-divider></v-divider>
            </div>
            <div class="order-7">
                <v-menu rounded="0" :close-on-content-click="true" offset-x v-model="snapshotMenu" max-width="300" open-on-hover :close-delay="closingDelay">
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" :input-value="$route.path.includes('manage-snapshots')">
                            <v-list-item-icon>
                                <v-icon>camera_alt</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>snapshot actions</v-list-item-title>
                        </v-list-item>
                    </template>
                    <v-list nav dense>
                        <v-list-item
                            v-if="currentSpaceType !== spaceTypes.VENDOR_SPACE && isDevelopment === true && isInstanceEditor === true"
                            @click="addSnapshot(snapshotTypes.QUICK_SNAPSHOT)"
                        >
                            <v-list-item-title class="subtitle-2 d-flex align-center"
                                ><v-icon small class="mr-1">mdi-camera-plus-outline</v-icon>Quick snapshot</v-list-item-title
                            >
                            <v-list-item-action>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small v-on="on" color="grey lighten-1">mdi-information</v-icon>
                                    </template>
                                    <span>This will create and save a snapshot of your current state, including all your files, tables, and applications.</span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item
                            v-if="isDevelopment === true && isInstanceEditor === true"
                            :to="{
                                name: 'snapshot-add-snapshot',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                            }"
                        >
                            <v-list-item-title class="subtitle-2 d-flex align-center"
                                ><v-icon small class="mr-1">mdi-camera-plus</v-icon>Take snapshot and describe</v-list-item-title
                            >
                            <v-list-item-action>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small v-on="on" color="grey lighten-1">mdi-information</v-icon>
                                    </template>
                                    <span>Create a snapshot of your current state and provide detailed description.</span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item
                            :to="{
                                name: 'instance-snapshots',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                            }"
                        >
                            <v-list-item-title class="subtitle-2 d-flex align-center"
                                ><v-icon color="info" small class="mr-1">timeline</v-icon>View snapshot timeline</v-list-item-title
                            >
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div class="order-8">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item
                            v-on="on"
                            :to="{
                                name: 'snapshot-distribute',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                            }"
                        >
                            <v-list-item-icon>
                                <v-badge :color="numberOfStagedObjects > 0 ? 'secondary' : 'primary'" overlap class="caption" v-model="showBadge" bottom>
                                    <template v-slot:badge>
                                        <v-fab-transition>
                                            <span v-text="numberOfStagedObjects" fab></span>
                                        </v-fab-transition>
                                    </template>
                                    <v-icon>share</v-icon>
                                </v-badge>
                            </v-list-item-icon>
                            <v-list-item-title>Distribute objects</v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>Distribute objects</span>
                </v-tooltip>
            </div>
            <div class="order-9">
                <ApplicationMenu />
            </div>
        </div>

        <template v-slot:append> </template>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { snapshotEnums } from '@/mixins/snapshot'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import { createQuickAndDetailedSnapshots } from '@/mixins/createSnapshot'
const ApplicationMenu = () => import('../components/TheApplicationSidebarMenu')
const SettingsMenu = () => import('../components/TheSnapshotSettingsMenu')

export default {
    components: { SettingsMenu, ApplicationMenu },
    mixins: [appTypeAndImageLink, enumsData, createQuickAndDetailedSnapshots, snapshotEnums],
    data() {
        return {
            fab: false,
            showBadge: true,
            snapshotMenu: false,
            manageMenu: false,
            closingDelay: 100
        }
    },
    computed: {
        numberOfStagedObjects() {
            return this.stagingObjects.files.length + this.stagingObjects.tables.length + this.stagingObjects.applications.length
        },
        ...mapGetters('instanceStore', ['isInstanceEditor']),
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'currentSpaceType']),
        ...mapGetters('snapshotStore', ['currentFileLocalPath', 'isDevelopment', 'developmentSnapshot']),
        ...mapState('snapshotStore', ['stagingObjects', 'fileAreaType']),
        ...mapState(['userInfo'])
    },
    watch: {
        numberOfStagedObjects: function(nextValue, preValue) {
            if (nextValue !== preValue) {
                this.$data.showBadge = false
                setTimeout(() => {
                    this.$data.showBadge = true
                }, 300)
            }
        }
    }
}
</script>

<style scoped>
.topMenu--override {
    top: 0px !important;
    height: 48px !important;
    border-bottom-left-radius: 0px;
}
</style>
